<template>
	<div class="pg_branch_item_kpi">
		<div class="pg_branch_item_kpi_label">
			<div class="pg_branch_item_kpi_label_text">{{ title }}</div>
			<div class="pg_branch_item_kpi_label_deviation">
				<div
					v-show="statistics.before !== 0 && statistics.current !== 0"
					class="pg_deviation a_margin-right-10"
					:class="[statistics.compare >= 0 ? 'v_positive' : 'v_negative']"
				>
					{{ statistics.compare | valueFormatter }}%
				</div>
			</div>
		</div>
		<div class="pg_branch_item_kpi_no">{{ statistics.current }}</div>
		<div class="pg_kpis_label-before">{{ previousPeriodText }}: {{ statistics.before }}</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";
import getPeriodText from "@/utils/get-period-text";

export default {
	components: {
		Loader,
	},
	props: {
		soort: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: "",
		},
		location: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			statistics: {
				current: 0,
				compare: 0,
				before: 0,
			},
			loading: false,
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
		previousPeriodText() {
			if (this.query.vergelijkMet === "vorige-periode") {
				if (this.query.periodType === "variabel") {
					if (this.query.days === 7) {
						return "vorige 7 dagen";
					}
					if (this.query.days === 28) {
						return "vorige 28 dagen";
					}
				}
				// if `query.periodType` === 'normal'
				if (this.query.periode === "dag") {
					return "vorige dag";
				}
				if (this.query.periode === "week") {
					return "vorige week";
				}
				if (this.query.periode === "maand") {
					return "vorige maand";
				}
				if (this.query.periode === "jaar") {
					return "vorig jaar";
				}
			}
			// if `query.vergelijkMet` === 'vorig-jaar'
			return getPeriodText(this.query, true);
		},
	},
	watch: {
		query: {
			handler() {
				this.fetchStatistics();
			},
			deep: true,
		},
	},
	mounted() {
		this.fetchStatistics();
	},
	methods: {
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		async fetchStatistics() {
			this.loading = true;
			this.statistics.current = 0;
			this.statistics.before = 0;
			this.statistics.compare = 0;
			await Promise.all([
				this.getByPeriod({
					...this.query,
					soort: this.soort,
					location: this.location,
				}).then((result) => {
					this.statistics.current = result.huidige.waarde;
					this.statistics.before = result.vorige.waarde;
					this.statistics.compare =
						this.statistics.current === this.statistics.before
							? 0
							: ((this.statistics.current - this.statistics.before) /
									Math.abs(this.statistics.before)) *
								100;
				}),
				// this.getCompareByPeriod({ ...this.query, soort: this.soort }).then((result) => {
				//     this.statistics.compare = result.overigeVestigingen.waarde;
				// }),
			]).catch(logger.error);
			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 36px - 6px);
	left: calc(100% - 36px - 6px);
}
</style>
