import moment from "moment";

export default (query, compareToPreviousYear) => {
	const diffType = (() => {
		return {
			dag: "days",
			week: "weeks",
			maand: "months",
			jaar: "years",
		}[query.periode];
	})();
	const currentDate = (() => {
		return query && query.opening
			? query.opening.clone().add(query.index, diffType).startOf(diffType.slice(0, -1))
			: moment();
	})();
	const date = currentDate;
	if (query.periodType === "variabel") {
		return `${moment()
			.subtract(query.days - 1, "days")
			.format("DD MMMM")} - ${moment()
			.subtract(compareToPreviousYear ? 1 : 0, "years")
			.format("DD MMMM YYYY")} `;
	}
	switch (query.periode) {
		case "dag":
			return date.subtract(compareToPreviousYear ? 1 : 0, "years").format("DD MMMM YYYY");
		case "week":
			return `WEEK ${date.week()}, ${date
				.subtract(compareToPreviousYear ? 1 : 0, "years")
				.format("YYYY")}`;
		case "maand":
			return date.subtract(compareToPreviousYear ? 1 : 0, "years").format("MMMM YYYY");
		case "jaar":
			return date.subtract(compareToPreviousYear ? 1 : 0, "years").format("YYYY");
		default:
			return "[?]";
	}
};
