<template>
	<div v-sticky="{ zIndex: 4444, stickyTop: 0 }" class="pg_period">
		<div class="pg_period_content">
			<div class="pg_period_container">
				<div class="pg_period_selects">
					<div class="pg_period_select">
						<label>vergelijk met</label>
						<select v-model="query.vergelijkMet" class="v_transparent">
							<option value="vorige-periode">vorige periode</option>
							<option value="vorig-jaar">zelfde periode vorig jaar</option>
						</select>
					</div>
					<div class="pg_period_select_term">
						<div class="pg_period_select_term_line">
							<input
								id=""
								v-model="query.periodType"
								type="radio"
								name="periodType"
								value="variabel"
							/>
							<label for="">afgelopen</label>
							<select
								v-model="query.days"
								:disabled="query.periodType !== 'variabel'"
								class="v_transparent"
							>
								<option :value="7">7 dagen</option>
								<option :value="28" selected>28 dagen</option>
							</select>
						</div>
						<div class="pg_period_select_term_line">
							<input v-model="query.periodType" type="radio" name="periodType" value="normal" />
							<label>bekijk per</label>
							<select
								v-model="query.periode"
								:disabled="query.periodType !== 'normal'"
								class="v_transparent"
							>
								<option value="dag">Dag</option>
								<option value="week" selected>Week</option>
								<option value="maand">Maand</option>
								<option value="jaar">Jaar</option>
							</select>
						</div>
					</div>
				</div>
				<div class="pg_period_buttons">
					<button
						:class="{ s_invisible: query.periodType === 'variabel' }"
						:disabled="query.index <= 0 || query.periodType === 'variabel'"
						class="button v_transparent v_is-icon v_icon-size-20 a_margin-right-20 a_margin-bottom-0 a_no-padding-right a_no-padding-left"
						@click="seekIndex(-1)"
					>
						<icon :icon-id="'icon_arrow-left'"></icon>
					</button>
					<!-- <div class="pg_period_current">{{ query.periode }} {{query.index + 1}}</div> -->
					<div :key="query.index" class="pg_period_current">
						{{ getTitle() }}
					</div>
					<button
						:class="{ s_invisible: query.periodType === 'variabel' }"
						:disabled="query.index >= seekerIndexesCount || query.periodType === 'variabel'"
						class="button v_transparent v_is-icon v_icon-size-20 a_margin-left-20 a_margin-bottom-0 a_no-padding-right a_no-padding-left"
						@click="seekIndex(1)"
					>
						<icon :icon-id="'icon_arrow-right'"></icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import VueSticky from "vue-sticky";
import { mapActions, mapState } from "vuex";

export default {
	name: "Period",
	directives: {
		sticky: VueSticky,
	},
	data() {
		return {
			title: "",
			firstBranch: "",
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { branches: "items" }),
		diffType() {
			return {
				dag: "days",
				week: "weeks",
				maand: "months",
				jaar: "years",
			}[this.query.periode];
		},
		currentDate() {
			return this.query && this.query.opening
				? this.query.opening
						.clone()
						.add(this.query.index, this.diffType)
						.startOf(this.diffType.slice(0, -1))
				: moment();
		},
		seekerIndexesCount() {
			return this.firstBranch && this.firstBranch.opening
				? Math.abs(this.firstBranch.opening.diff(Date.now(), this.diffType))
				: 0;
		},
	},
	watch: {
		"query.periode": function queryPeriod() {
			this.query.index = this.seekerIndexesCount;
		},
		// firstBranch: {
		//     immediate: true,
		//     handler() {
		//         debugger;
		//         this.query.opening = this.firstBranch.opening;
		//         this.query.index = this.seekerIndexesCount;
		//     },
		// },
	},
	mounted() {
		this.firstBranch =
			this.branches && this.branches.length > 0
				? this.branches.sort((a, b) => a.opening.diff(b.opening, "days"))[0]
				: {};
		this.query.opening = this.firstBranch.opening;
		this.query.index = this.seekerIndexesCount;
	},
	methods: {
		...mapActions("test", ["test"]),
		seekIndex(value) {
			this.query.index += value;
			this.title = this.getTitle();
		},
		getTitle() {
			const date = this.currentDate;
			if (this.query.periodType === "variabel") {
				return `${moment()
					.subtract(this.query.days - 1, "days")
					.format("DD MMMM")} - ${moment().format("DD MMMM")} `;
			}
			switch (this.query.periode) {
				case "dag":
					return date.format("dddd DD MMMM YYYY");
				case "week":
					return `WEEK ${date.week()}, ${date.format("YYYY")}`;
				case "maand":
					return date.format("MMMM YYYY");
				case "jaar":
					return date.format("YYYY");
				default:
					return "[?]";
			}
		},
	},
};
</script>

<style lang="scss">
// .pg_period {
//     label {
//         color: $brand-primary-lighter;
//     }
//     select {
//         font-size: 90%;
//         background-position: right -1.4rem center;
//         margin-bottom: 0;
//     }
// }
// .pg_period_content  {
//     margin-bottom: 1em;

//     @include breakpoint(xlarge) {
//         margin-top: -2em;
//     }
//     @include breakpoint(xxlarge) {
//         margin-top: -3em;
//     }
// }
// .pg_period_container {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     background: $brand-lightest-gray;
//     padding: 1em $default-padding;
//     max-width: 1000px;
//     margin: 0 auto;
// }
// .pg_period_current {
//     text-transform: uppercase;
//     font-weight: 300;
//     font-size:  .9em;
//     margin: 0 .2em;
// }
// .pg_period_buttons {
//     display: flex;
//     align-items: center;
//     justify-content:space-between;
//     min-width: 300px;
// }
// .pg_period_selects {
//     display: flex;
//     margin-right: 4em;
// }
// .pg_period_select {
//     display: flex;
//     align-items: baseline;
//     &:nth-child(1) {
//         border-right: solid 1px $brand-primary-lightest;
//         padding-right: 1em;
//         margin-right: 1em;
//         select {
//              width: 120px;
//         }
//     }
//     &:nth-child(2) {
//         select {
//              width: 90px;
//         }
//     }
// }
</style>
