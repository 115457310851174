<template>
	<div>
		<period></period>
		<section class="pg_content">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h1 class="s_elem-has-icon-left a_fill_default">
								Home
								<icon :icon-id="'icon_coaches'"></icon>
							</h1>
						</div>
						<div class="pg_header_description">
							<p>De belangrijkste indicatoren van alle vestigingen.</p>
						</div>
					</header>
					<div v-if="userInfo.admin">
						<branch-kpi
							v-for="(branch, i) in sortedBranches"
							:key="'branch-key-' + i"
							:branch="branch"
							@click="navigateToBranchKpi(branch)"
						/>
					</div>
					<branch-kpi v-else :branch="selected" @click="navigateToBranchKpi(selected)" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Period from "@/components/elements/FreePeriod";
import BranchKpi from "./partials/BranchKpi";

export default {
	name: "Home",
	components: {
		Period,
		BranchKpi,
	},
	computed: {
		...mapState("auth", ["userInfo"]),
		...mapState("branches", { branches: "items", selected: "selected" }),
		sortedBranches() {
			return this.branches.slice().sort((a, b) => a.vestigingNaam.localeCompare(b.vestigingNaam));
		},
	},
	methods: {
		...mapMutations("branches", {
			selectBranch: "select",
		}),
		navigateToBranchKpi(branch) {
			this.selectBranch(branch);
			this.$router.push("kpi-dashboard");
		},
	},
};
</script>

<style lang="scss" scoped></style>
