<template>
	<div class="pg_branches" @click="$emit('click', branch)">
		<!-- <div class="pg_branch_item" v-for="(vestiging, i) in vestigingen"> -->
		<div class="pg_branch_item">
			<div class="pg_branch_item_top">
				<div class="pg_branch_item_top_content">
					<div class="pg_branch_item_top_content_left">
						<div class="pg_branch_item_title">{{ branch.vestigingNaam }}</div>
					</div>
					<div class="pg_branch_item_top_content_right">
						<!-- <div class="pg_avatar v_smallest">
                            <div class="pg_avatar_name a_margin-right-10">Karel Manakker</div>
                            <figure class="pg_avatar_image" style="background-image: url(static/img/trainers/1.jpg);"></figure>
                        </div> -->
					</div>
				</div>
				<div class="pg_branch_item_top_image_container">
					<figure
						v-if="branch.afbeeldingen.length > 0"
						class="pg_branch_item_top_image a_cover-cc"
						:style="{
							'background-image': `url(${branch.afbeeldingen[branch.afbeeldingen.length - 1].url})`,
						}"
					></figure>
				</div>
			</div>
			<div class="pg_branch_item_bottom">
				<statistics-badge-square
					:location="branch.mappingNaam"
					title="Leden"
					soort="aantal-leden"
				></statistics-badge-square>
				<statistics-badge-square
					:location="branch.mappingNaam"
					title="Bezoeken"
					soort="aantal-bezoeken"
				></statistics-badge-square>
				<statistics-badge-square
					:location="branch.mappingNaam"
					title="Acties"
					soort="aantal-acties"
				></statistics-badge-square>
				<statistics-badge-square
					:location="branch.mappingNaam"
					title="Groepsles deelnemers"
					soort="aantal-les-deelnames"
				></statistics-badge-square>
				<!-- <statistics-badge-square title="Beoordeling" soort="aantal-leden"></statistics-badge-square> -->
			</div>
		</div>
	</div>
</template>

<script>
import StatisticsBadgeSquare from "@/components/elements/StatisticsBadgeSquare";

export default {
	components: {
		StatisticsBadgeSquare,
	},
	props: { branch: { type: Object, default: () => ({}) } },
};
</script>
